/* Redundante com o sales/salesList.css */
.tableList {
  display: block;
  overflow: auto;
  max-height: 57vh;
  width: 100%;
}

.tableList::-webkit-scrollbar-track{
	background-color: #F5F5F5;
}

.tableList::-webkit-scrollbar{
	width: 10px;
}
/* Fim da redundancia */

.table-logo{
  width: 15%;
  margin-right: 5%;
}

@media only screen and (min-width: 769px){
  br {
    display: none;
  }
}

@media only screen and (max-width: 769px){
  .table-logo{
    margin-right: 0px;
  }

  .break-mobile{
    display: block;
  }
  
  .table-logo{
    display: none;
  }
}